<script setup>
	import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
	import { ElMessage } from 'element-plus'
	import axios from 'axios';
	import { useMemberStore } from '../../stores';
	const userStore = useMemberStore();
	const baseURL = 'https://mini.walkertour.cn';
	const rootPath = "/www/wwwroot/miniServer/server/dist";
	const headers = ref({
		'Authorization': `${userStore.profile?.token}`,
	})
	
	const onUploadImg = (files) => {
	  console.log(files)
	}
	const mode = 'default' // 或 'simple'

	// 编辑器实例，必须用 shallowRef
	const editorRef = shallowRef()

	// 内容 HTML
	const valueHtml = ref('')
	const size = ref('default')
	const labelPosition = ref('right')
	const sizeForm = ref({
		spmc:'',//商品名称 
		yjg:'',//原价格
		yhjg:'',//优惠价格
		carousel:'',//轮播图
		kssj:'',//可以预定开始时间
		jssj:'',//可以预定结束时间
		spxq:'',//商品描述
		zt:'1',//上架、下架
		latitude:'',
		longitude:'',
		dz:'',
		phone:'',
		city:'',
		tags:''
	});
	const onSubmit=async()=> {
		let isNull = false;
		// Object.keys(sizeForm.value).forEach(key=>{
		// 	if(sizeForm.value[key]===''){
		// 		isNull = true;
		// 	}
		// })
		if(!isNull){
			const response = await axios.post('/goods/manage/saveOrUpdate', {
			  ...sizeForm.value
			}, {
			  headers: {
				'Content-Type': 'application/json',
				Authorization: `${userStore.profile?.token}`,
			  }
			});
			if(response.data.status===0){
				ElMessage.success('添加成功!');
				sizeForm.value = {
					spmc:'',//商品名称
					yjg:'',//原价格
					yhjg:'',//优惠价格
					carousel:'',//轮播图
					kssj:'',//可以预定开始时间
					jssj:'',//可以预定结束时间
					spxq:'',//商品描述
					zt:'1',
					latitude:'',
					longitude:'',
					dz:'',
					phone:'',
					city:'',
					tags:''
				}
			}
		}else{
			ElMessage.error('所有信息不可为空!')
		}
	}
	// 模拟 ajax 异步获取内容
	onMounted(() => {
	 //  setTimeout(() => {
		// valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
	 //  }, 1500)
	})

	const toolbarConfig = {}
	const editorConfig = {
	  placeholder: '请输入内容...',
	  MENU_CONF: {
		uploadImage: {
		  // mock地址
		  server: 'https://server.walkertour.cn/goods/manage/uploadFile',
		  // 小于该值就插入 base64 格式（而不上传），默认为 0
		  base64LimitSize: 5000 * 1024 // 5M
		}
	  }
	}
	// 组件销毁时，也及时销毁编辑器
	onBeforeUnmount(() => {
	  const editor = editorRef.value
	  if (editor == null) return
	  editor.destroy()
	})
	const handleCreated = (editor) => {
	  editorRef.value = editor // 记录 editor 实例，重要！
	}
	const data = ref({
		billid:`${userStore.profile?.userid}`
	})
	const handleAvatarSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
	  	if(sizeForm.value['carousel']===''){
			sizeForm.value['carousel'] = response.imgUrl.replace(rootPath,baseURL);
		}else{
			sizeForm.value['carousel'] += "," + response.imgUrl.replace(rootPath,baseURL);
		} 
		// fileList.value.push({
		// 	name:response.name,
		// 	url:response.imgUrl.replace(rootPath,baseURL)
		// })
	  }
	}
	
	const beforeAvatarUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 4) {
	    ElMessage.error('Avatar picture size can not exceed 4MB!')
	    return false
	  }
	  return true
	}
	const handleRemove=(file,uploadFiles)=>{
		sizeForm.value['carousel'] = '';
		uploadFiles.map(item=>{
			if(sizeForm.value['carousel']===''){
				sizeForm.value['carousel'] = item.url
			}else{
				sizeForm.value['carousel'] += "," + item.url
			}
		})
		 
	}
	const shortcuts0 = [
	  {
		text: '今　天',
		value: ()=>{
			return formatDateTime(new Date());
		}
	  },{
		text: '明　天',
		value: () => {
		  const date = new Date()
		  date.setTime(date.getTime() + 3600 * 1000 * 24)
		  return formatDateTime(date);
		},
	  },{
		text: '一周后',
		value: () => {
		  const date = new Date()
		  date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
		  return formatDateTime(date);
		},
	  },{
		text: '一月后',
		value: () => {
		  const date = new Date()
		  date.setTime(date.getTime() + 3600 * 1000 * 24 * 31)
		  return formatDateTime(date);
		},
	  },
	]
	const shortcuts1 = [
	  {
		text: '今　天',
		value: ()=>{
			return formatDateTime1(new Date());
		}
	  },{
		text: '明　天',
		value: () => {
		  const date = new Date()
		  date.setTime(date.getTime() + 3600 * 1000 * 24)
		  return formatDateTime1(date);
		},
	  },{
		text: '一周后',
		value: () => {
		  const date = new Date()
		  date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
		  return formatDateTime1(date);
		},
	  },{
		text: '一月后',
		value: () => {
		  const date = new Date()
		  date.setTime(date.getTime() + 3600 * 1000 * 24 * 31)
		  return formatDateTime1(date);
		},
	  },
	]
	const defaultTime0 = (a) => {
		var now = new Date(); // 获取当前日期和时间
		now.setHours(a); // 设置小时为8（24小时制）
		now.setMinutes(0);
		now.setSeconds(0);
		return now
	}
	const defaultTime1 = (a) => {
		var now = new Date(); // 获取当前日期和时间
		now.setHours(a); // 设置小时为8（24小时制）
		now.setMinutes(59);
		now.setSeconds(59);
		return now
	}
	const formatDateTime = (date)=>{
		function padZero(num) {
			return num < 10 ? '0' + num : num;
		}
		const year = date.getFullYear();
		const month = padZero(date.getMonth() + 1);
		const day = padZero(date.getDate());
		return `${year}-${month}-${day} 00:00:00`;
	}
	const formatDateTime1 = (date)=>{
		function padZero(num) {
			return num < 10 ? '0' + num : num;
		}
		const year = date.getFullYear();
		const month = padZero(date.getMonth() + 1);
		const day = padZero(date.getDate());
		return `${year}-${month}-${day} 23:59:59`;
	}
	const fileList = ref([])
	const dialogImageUrl = ref('')
	const dialogVisible = ref(false)
	const handlePictureCardPreview=(uploadFile) => {
	  dialogImageUrl.value = uploadFile.url
	  dialogVisible.value = true
	}
	const options = [
	  {
		value: '首尔',
		label: '首尔',
	  },
	  {
		value: '釜山',
		label: '釜山',
	  },
	  {
		value: '济州',
		label: '济州',
	  }
	]
</script>
<template>
	<div style="height: 560px;overflow-y: scroll;">
		<el-form
		    ref="form"
		    :model="sizeForm"
		    label-width="auto"
		    :label-position="labelPosition"
		    :size="size"
		>
			<div style="display: flex;" >
				<el-form-item label="商品名称" style="width: 92%;" prop="spmc" :rules="[{ required: true, message: '`名称` is required' }]">
				  <el-input class="w-50 m-2" v-model="sizeForm.spmc" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="详细地址" style="width: 92%;" prop="dz" :rules="[{ required: true, message: '`详细地址` is required' }]">
				  <el-input class="w-50 m-2" v-model="sizeForm.dz" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="所在城市" style="width: 48%;" prop="city" :rules="[{ required: true, message: '`所在城市` is required' }]">
				  <el-select
				      v-model="sizeForm.city"
				      clearable
				      placeholder="请选择"
				      style="width: 240px"
				    >
				      <el-option
				        v-for="item in options"
				        :key="item.value"
				        :label="item.label"
				        :value="item.value"
				      />
				    </el-select>
				</el-form-item>
				<el-form-item label="酒店电话" style="width: 48%;" prop="phone" :rules="[{ required: true, message: '`酒店电话` is required' }]">
				  <el-input class="w-50 m-2" v-model="sizeForm.phone" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="酒店设施" style="width: 92%;" prop="tags" :rules="[{ required: true, message: '`酒店设施` is required' }]">
				  <el-input class="w-50 m-2" v-model="sizeForm.tags" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="经度" style="width: 48%;" prop="longitude" :rules="[{ required: true, message: '`经度` is required' }]">
				  <el-input class="w-50 m-2" v-model="sizeForm.longitude" />
				</el-form-item>
				<el-form-item label="纬度" style="width: 48%;" prop="latitude" :rules="[{ required: true, message: '`纬度` is required' }]">
				  <el-input class="w-50 m-2" v-model="sizeForm.latitude" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="轮播实图(最多6张)" style="width: 92%;">
				  <el-upload
				    class="avatar-uploader"
					:limit="6"
					v-model:file-list="fileList"
					list-type="picture-card"
				    action="https://server.walkertour.cn/goods/manage/uploadFile"
				    :headers="headers"
					:data="data"
				    :on-success="handleAvatarSuccess"
				    :before-upload="beforeAvatarUpload"
					:on-remove="handleRemove"
					:on-preview="handlePictureCardPreview"
					crossorigin="anonymous"
				  >
				    <el-icon class="avatar-uploader-icon"><Plus /></el-icon>
				  </el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="原价格" style="width: 48%;" prop="yjg" :rules="[{ required: true, message: '`原价格` is required' }]">
				  <el-input class="w-50 m-2" type="text" v-model="sizeForm.yjg" placeholder="请输入原价格" />
				</el-form-item>
				<el-form-item label="优惠价格" style="width: 48%;" prop="yhjg" :rules="[{ required: true, message: '`优惠价格` is required' }]">
					<el-input class="w-50 m-2" type="text" v-model="sizeForm.yhjg" placeholder="请输入优惠价格" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="有效期开始" style="width: 48%;" prop="kssj" :rules="[{ required: true, message: '`开始时间` is required' }]">
				  <el-date-picker
				  	v-model="sizeForm.kssj"
				  	type="datetime"
				  	placeholder="开始预定日期和时间"
				  	:shortcuts="shortcuts0"
				  	format="YYYY-MM-DD HH:mm:ss"
				  	:default-time="defaultTime0(0)"
				  	value-format="YYYY-MM-DD HH:mm:ss"
				  />
				</el-form-item>
				<el-form-item label="有效期结束" style="width: 48%;" prop="jssj" :rules="[{ required: true, message: '`结束时间` is required' }]">
				  <el-date-picker
				  	v-model="sizeForm.jssj"
				  	type="datetime"
				  	placeholder="结束预定日期和时间"
				  	:shortcuts="shortcuts1"
				  	format="YYYY-MM-DD HH:mm:ss"
				  	:default-time="defaultTime1(23)"
				  	value-format="YYYY-MM-DD HH:mm:ss"
				  />
				</el-form-item>
			</div>
<!-- 			<div style="display: flex;">
				<el-form-item label="商品详情" style="width: 92%;" prop="spxq" :rules="[{ required: true, message: '`商品详情` is required' }]">
				  <div class="mx-4 shadow">
				    <Toolbar
				  	style="border-bottom: 1px solid #ccc"
				  	:editor="editorRef"
				  	:defaultConfig="toolbarConfig"
				  	:mode="mode"
				    />
				    <Editor
				  	style="height: 400px; overflow-y: hidden"
				  	v-model="sizeForm.spxq"
				  	:defaultConfig="editorConfig"
				  	:mode="mode"
				  	@onCreated="handleCreated"
				    />
				  </div>
				</el-form-item>
			</div> -->
			<div style="display: flex;">
				<el-form-item label="状态">
				  <el-radio-group v-model="sizeForm.zt">
					<el-radio border label="上架" value="0"/>
					<el-radio border label="下架" value="1"/>
				  </el-radio-group>
				</el-form-item>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-form-item>
			      <el-button type="primary" @click="onSubmit">保存</el-button>
			    </el-form-item>
			</div>
		</el-form>
		<el-dialog v-model="dialogVisible">
			<img w-full :src="dialogImageUrl" alt="Preview Image" />
		</el-dialog>
	</div>
</template>
<style scoped>
	.avatar-uploader .avatar {
	  width: 128px;
	  height: 128px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
</style>