<script setup>
	import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
	import { ElMessage } from 'element-plus'
	import axios from 'axios';
	import { useMemberStore } from '../../../stores';
	const userStore = useMemberStore();
	const baseURL = 'https://mini.walkertour.cn';
	const rootPath = "/www/wwwroot/miniServer/server/dist";
	const headers = ref({
		'Authorization': `${userStore.profile?.token}`,
	})
	const size = ref('default')
	const labelPosition = ref('right')
	const sizeForm = ref({
		xm:{'中文':'','韩文':'','英文':''},//姓名
		zc:{'中文':'','韩文':'','英文':''},//职称
		scxm:{'中文':'','韩文':'','英文':''},//擅长项目
		create:'',//添加时间
		avatar:'',//头像
		ssyy:'',//任职医院
	});
	const options = ref([{
		value: '1',
		label: 'laprin',
	  }])
	onMounted(()=>{
	})
	const coverFileList = ref([])
	const onSubmit=async()=> {
		const date = new Date();
		const year = date.getFullYear();
		const month = (date.getMonth() + 1)<10?"0"+(date.getMonth() + 1):(date.getMonth() + 1);
		const day = date.getDate()<10?"0"+date.getDate():date.getDate();
		const hours = date.getHours()<10?"0"+date.getHours():date.getHours();
		const minutes = date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes();
		const seconds = date.getSeconds()<10?"0"+date.getSeconds():date.getSeconds();
		sizeForm.value['create'] =  year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
		const response = await axios.post('/doctor/info/saveOrUpdate', {
		  sizeForm:JSON.stringify(sizeForm.value),
		  jyjlValue:JSON.stringify(jyjlValue.value),
		  gjcValue:JSON.stringify(gjcValue.value)
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage.success('添加成功!');
			sizeForm.value = {
				xm:{'中文':'','韩文':'','英文':''},//姓名
				zc:{'中文':'','韩文':'','英文':''},//职称
				scxm:{'中文':'','韩文':'','英文':''},//擅长项目
				create:'',//添加时间
				avatar:'',//封面
				ssyy:'',//任职医院
			}
			jyjlValue.value = {'中文':[],'韩文':[],'英文':[]}
		}
	}
	const data = ref({
		billid:`${userStore.profile?.userid}`
	})
	const coverData = ref({
		billid:`${userStore.profile?.userid}`,
		type:'avatar'
	})
	const handleCoverSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['avatar'] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeCoverUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleCoverRemove=(file,uploadFiles)=>{
		sizeForm.value['avatar'] = '';
	}
	
	const handleAvatarSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['dt'][`${triggerDt.value}`] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeAvatarUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleRemove=(file,uploadFiles)=>{
		sizeForm.value['dt'][`${triggerDt.value}`] = '';
	}
	//标题
	const optionsBt = ref(['中文','韩文','英文'])
	const triggerBt = ref('中文')
	//描述
	const optionsMs = ref(['中文','韩文','英文'])
	const triggerMs = ref('中文')
	//地址
	const optionsDz = ref(['中文','韩文','英文'])
	const triggerDz = ref('中文')
	//关键词
	const optionsGjc = ref(['中文','韩文','英文'])
	const triggerGjc = ref('中文')
	const gjcValue = ref({'中文':[],'韩文':[],'英文':[]})
	//教育经历
	const optionsScxm = ref(['中文','韩文','英文'])
	const triggerScxm = ref('中文')
	const jyjlValue = ref({'中文':[],'韩文':[],'英文':[]})
	const addSPaInfo=()=>{
		jyjlValue.value[`${triggerScxm.value}`].push({
			name:'',
			value:''
		})
	}
</script>
<template>
	<div style="height: 800px;overflow-y: scroll;">
		<el-form
		    ref="form"
		    :model="sizeForm"
		    label-width="auto"
		    :label-position="labelPosition"
		    :size="size"
		>
			<div style="display: flex;">
				<el-form-item label="头像" style="width: 92%;">
					<el-upload
						class="avatar-uploader"
						:limit="1"
						v-model:file-list="coverFileList"
						list-type="picture-card"
						action="https://server.walkertour.cn/doctor/info/uploadFile"
						:headers="headers"
						:data="coverData"
						:on-success="handleCoverSuccess"
						:before-upload="beforeCoverUpload"
						:on-remove="handleCoverRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="姓名" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerBt" :options="optionsBt" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.xm[`${triggerBt}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="职称" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerMs" :options="optionsMs" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.zc[`${triggerMs}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="擅长项目" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerDz" :options="optionsDz" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.scxm[`${triggerDz}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="就职医院" style="width: 92%;">
				  <el-select
				      v-model="sizeForm.ssyy"
				      clearable
				      placeholder="请选择"
				      style="width: 240px"
				    >
				      <el-option
				        v-for="item in options"
				        :key="item.value"
				        :label="item.label"
				        :value="item.value"
				      />
				    </el-select>
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="关键词" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerGjc" :options="optionsGjc" />
					</div>
					<br />
					<el-input-tag v-model="gjcValue[`${triggerGjc}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div>
				<el-form-item label="教育经历" style="width: 92%;" >
					<div>
						<el-segmented v-model="triggerScxm" :options="optionsScxm" />&nbsp;<el-button type="primary" @click="addSPaInfo">新增</el-button>
					</div>
				</el-form-item>
			</div>
			<div v-for="(input, index) in jyjlValue[`${triggerScxm}`]" :key="index" style="margin-top: 10px;width:92%;display: flex;align-items: center;">
			  <el-text class="mx-1" type="primary" style="width: 96px;">序号{{index+1}}</el-text>
			  <el-input v-model="input.value" placeholder="请输入教育经历"></el-input>
			  <el-icon><Minus /></el-icon>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-form-item>
			      <el-button type="primary" @click="onSubmit">保存</el-button>
			    </el-form-item>
			</div>
		</el-form>
	</div>
</template>
<style scoped>
	.avatar-uploader .avatar {
	  width: 128px;
	  height: 128px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
</style>